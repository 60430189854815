import React from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"
import EatingHistory from "../components/EatingHistory"
import Return from "../components/Forum/Return"

import { deleteDataFromApi, registerAction } from '../api'
import { PortalContext } from '../AuthenticatedUser'
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import Button from '../components/Button'

export default function UserHistory() {
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const [type, setType] = React.useState('all')
  
  const {
    sequenceLength,
    eating,
    league,
    loadEatingDataFromAPI,
    exercise,
    loadExerciseDataFromAPI,
    auth, 
    cookies } = React.useContext(PortalContext);
  
  const navigate = useNavigate()
  const location = useLocation()
  
  React.useEffect(() => {
    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/eating', { path: '/' })
      navigate("/family")
    } else {
      if (!eating || !eating?.history) {
        loadEatingDataFromAPI()
      }
      if (!exercise || !exercise?.history) {
        loadExerciseDataFromAPI()
      }
    }
    registerAction(+cookies.get("userId"), "historypage", auth, cookies)
  }, []);

  let historyItems = []

  if (exercise?.history && eating?.history) {

    const exerciseHistoryFiltered = exercise.history.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)
    const mealsHistoryFiltered = eating.history.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)
    
    if (type === "all") {
      historyItems = historyItems.concat(mealsHistoryFiltered)
      historyItems = historyItems.concat(exerciseHistoryFiltered)
    } else if (type === "eating") {
      historyItems = historyItems.concat(mealsHistoryFiltered)
    } else if (type === "exercise") {
      historyItems = historyItems.concat(exerciseHistoryFiltered)
    }
  }
  
  //const removeFood = (id) => {
  //  console.log(`removing food ${id}`)
  //  deleteDataFromApi({"item_id": +id, "id": +cookies.get("userId")}, 'food', auth, cookies)
  // setMealsHistory(prev => prev.filter(item => item.id !== id))
  //}
  
  //const removeExercise = (id) => {
  //  console.log(`removing exercise ${id}`)
  //  deleteDataFromApi({"item_id": +id, "id": +cookies.get("userId")}, 'exercise', auth, cookies)
  //  setExerciseHistory(prev => prev.filter(item => item.id !== id))
  //}

  if (!exercise?.history || !eating?.history) {
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
    )
  }

  //const exerciseHistoryFiltered = exercise.history.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)
  //const mealsHistoryFiltered = eating.history.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)

  //if (type === "all") {
  //  historyItems = historyItems.concat(mealsHistoryFiltered)
  //  historyItems = historyItems.concat(exerciseHistoryFiltered)
  //} else if (type === "eating") {
  //  historyItems = historyItems.concat(mealsHistoryFiltered)
  //} else if (type === "exercise") {
  //  historyItems = historyItems.concat(exerciseHistoryFiltered)
  //}

  let sequenceText1 = ""
  let sequenceText2 = ""
  let sequenceText3 = ""

  if (sequenceLength.length > 0 && sequenceLength.today === false) {
    if (sequenceLength.length === 1) {
      sequenceText1 = "1 dia"
      sequenceText2 = "em sequência!"
    } else if (sequenceLength.length > 1) {
      sequenceText1 = `${sequenceLength.length} dias`
      sequenceText2 = "em sequência!"
    }
    sequenceText3 = "Não perca sua sequência, você ainda não adicionou nada hoje"
  } else if (sequenceLength.length > 0 && sequenceLength.today === true) {
    if (sequenceLength.length === 1) {
      sequenceText1 = "1 dia"
      sequenceText2 = "em sequência!"
      sequenceText3 = "Você está no caminho certo, vamos fazer essa sequência crescer!"
    } else if (sequenceLength.length > 1) {
      sequenceText1 = `${sequenceLength.length} dias`
      sequenceText2 = "em sequência!"
      sequenceText3 = "Parabéns por aumentar sua sequência hoje!"
    }
  } else {
    sequenceText1 = "Nenhum dia"
    sequenceText2 = "em sequência"
    sequenceText3 = "Adicione uma refeição ou exercício para começar sua sequência!"
  }

  return (
    (!exercise?.history || !eating?.history) ? 
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
      :
      <>
        <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
          <Header 
            tabletBreakpoint={false} 
            menuItems="portal" 
            id={+cookies.get("userId")} 
            menuShow={true} 
            selected="sequence"
            sequenceLength={sequenceLength}
            hasObjectives={(eating && eating?.goals) ? eating.goals.length > 0 : false}
            league={(league?.is_in_current_league) ? league.is_in_current_league : false}
          />
        </div>
        <>
          <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>

          <div className="">
            <p className="florence-title top-margin-s">
              {sequenceText1} 
            </p>
            <p className="intra-page-title bottom-margin-xs">
              {sequenceText2}
            </p>
            {(sequenceText3) ?
            <p className="body bottom-margin-xs secondary-color">
              {sequenceText3}
            </p> : null }
          </div>

            {(location.state?.message) ?
              <div className="bottom-margin-m">
                <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={location.state.message} />
              </div>
            : null }

            <div className="bottom-margin-l">
              <EatingHistory 
                calendarItems={historyItems} 
                title={'Histórico de alimentação'} 
                tagged={false}
                grade={false}
                userAdd={false}
                icons={true}
                addItemtoArray={()=>{}}
                removeFoodItemFromArray={()=>{}}
                removeExerciseItemFromArray={()=>{}}
                type={type}
                setType={setType}
                highlightToday={false}
                confirmedColor={(sequenceLength.today) ? "streak" : "not-streak" }
              />
            </div>

          </div>
        </>
      </>
  )
}
