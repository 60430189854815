import React from 'react'
import Button from './Button'

export default function HealthHistorySubjectSelectionMenu({ chooseSubject, alternativeTitle=null, showAllOption=true }) {

  return (
    <div className={"container-simple-100vh container-full-width"} 
      style={{minHeight: "100vh"}}>
      <div className={"top-padding-l bottom-padding-m"}>
        <p className="body secondary-color center-text">
          Seus dados ficam separados por assunto
        </p>
        <p className="florence-title florence-title-smaller center-text">
          {(alternativeTitle) ? alternativeTitle : "Para qual você quer ir?"}
        </p>
      </div>

      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
      }}>
        <Button type={"primary btn-wider btn-taller"} onClick={()=>chooseSubject(0)}>
          Dados gerais
          <p className="secondary-text-smaller inv-text-color">Exames, vacinas, etc.</p>
        </Button>
        
        <Button type={"primary btn-wider btn-taller"} onClick={()=>chooseSubject(1)}>
          Problemas crônicos
          <p className="secondary-text-smaller inv-text-color">Hipertensão, diabetes, etc.</p>
        </Button>
        
        <Button type={"primary btn-wider btn-taller"} onClick={()=>chooseSubject(2)}>
          Problemas agudos
          <p className="secondary-text-smaller inv-text-color">Gripes, acidentes, etc.</p>
        </Button>
        
        { showAllOption &&
          <Button type={"tertiary btn-wider transparent-background"} onClick={()=>chooseSubject(-1)}>
            Quero ver todos
          </Button>
        }
      </div>

    </div>
  )
}
