import { format } from 'date-fns'
import React from 'react'
import { backendUrl } from '../api'

export default function HealthHistoryPhoto({ item, cardId, setModal, setModalId }) {
  
  const openModal = () => {

    setModal(prev => !prev)
    setModalId({ cardId, itemId: item.id, type: "photo" })
  }

  let heading = ""
  let imgUrl = ""
  if (item.type === "health_photo") {
    heading = "Fotos"
    imgUrl = `${backendUrl}/health/photo/internal`
  } else if (item.type === "vaccine") {
    heading = "Vacinas"
    imgUrl = `${backendUrl}/document/internal`
  } else if (item.type === "prescription") {
    heading = "Prescrições"
    imgUrl = `${backendUrl}/document/internal`
  } else if (item.type === "exam") {
    heading = "Exames"
    imgUrl = `${backendUrl}/document/internal`
  }

  let containerClass = ""
  if (item.filename.length === 1) {
    containerClass = "image-thumbnail-container-1"
  } else if (item.filename.length === 2) {
    containerClass = "image-thumbnail-container-2"
  } else {
    return (
      <button className="image-thumbnail-container-3" onClick={()=>openModal()}>
        <p className="heading5 card-element-floating-title">{heading} {format(item.date_created, 'dd/MM')}</p>
        <img className="health-history-image-thumbnail image-thumbnail-high" 
          src={`${imgUrl}/${item.filename[0].split('.')[0]}`} />
        <img className="health-history-image-thumbnail image-thumbnail-second" 
          src={`${imgUrl}/${item.filename[1].split('.')[0]}`} />
        <img className="health-history-image-thumbnail image-thumbnail-third" 
          src={`${imgUrl}/${item.filename[2].split('.')[0]}`} />
      </button>
    )
  }
  return (
      <button className={containerClass} onClick={()=>openModal()}>
        <p className="heading5 card-element-floating-title">
          {item.group_name} 
        </p>
        {item.filename.map((filename, index) => {
            if (index < 3) {
              return (
                <img className="health-history-image-thumbnail" key={index} 
                  src={`${imgUrl}/${filename.split('.')[0]}`} />
              )
            } else {
              return null
            }
          }
        )} 
      </button>
  )
}
