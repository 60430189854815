import React from 'react'
import AppStoreBadge from '../components/AppStoreBadge';
import PlayStore from '../images/play-store-icon.svg'
import AppStore from '../images/app-store-icon.svg'
import { useMediaQuery } from 'react-responsive'

export default function AppBadges({ center }) {
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  return (
    <div className={(tabletBreakpoint) ? 
      (center) ? "app-badges-container-centered" : "app-badges-container" : 
      (center) ? "app-badges-container-mobile-centered" : "app-badges-container-mobile"}>
      <AppStoreBadge icon={PlayStore} secondaryText="Disponível no" storeName="Google Play" link="https://play.google.com/store/apps/details?id=br.com.florencehealth" />
      <AppStoreBadge icon={AppStore} secondaryText="Baixe na" storeName="App Store" link="https://apps.apple.com/br/app/florence-health/id6743760512" />
    </div>
  )
}
