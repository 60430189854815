import React from "react"
import Dots from '../images/dots-horizontal.svg'
import closeMenuLogo from "../images/x-solid-black.svg"

export default function HealthHistoryToggleMenu({ setCreateNewHistory, setSearch=()=>{}, addNewItem=()=>{} }) {

  const [menuState, setMenuState] = React.useState(true)

  function toggleMenuState() {
    setMenuState(prev => !prev)
  }

  const menuOptions = [
    {key: 2, element: <>Criar novo ficheiro</>, onClick: () => {
      setCreateNewHistory()
      toggleMenuState()
    }},
  ]

  const menu = menuOptions.map(({ key, element, onClick }) => (
    <button key={key} onClick={onClick} className="health-history-menu-button right-padding-xs">
      {element}
    </button>
  ))

  return (
    <div className="top-padding-xxs">
      { 
      menuState ? (
          <button className={ "health-history-button-smaller"} onClick={()=>toggleMenuState()}>
            <img className={"button-icon-smaller"} src={Dots} alt="search button"/>
          </button>
        ) : (
          <>
            <button className={ "health-history-button-smaller"} onClick={()=>toggleMenuState()}>
              <img className={"button-icon-smaller"} src={Dots} alt="search button"/>
            </button>
            <div className="health-history-menu bottom-padding-xs">
              <button className="svg-btn" onClick={()=>toggleMenuState()}>
                <img className="health-history-small-menu-nav health-history-inv-svg" 
                    src={closeMenuLogo} alt="Button to toggle menu" />
              </button>
              {menu}
            </div>
          </>
        )
      }
    </div>
  )
}
