import React from 'react'
import Button from '../components/Button'
import closeMenuLogo from "../images/x-solid-black.svg"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import { format } from 'date-fns'
import { backendUrl, deleteDataFromApi } from '../api'
import HealthHistoryEditableTitle from './HealthHistoryEditableTitle'

export default function HealthHistoryPhotoModal({ modalId, setModalState }) {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { setHealthHistory, healthHistory } = React.useContext(PortalContext);
  
  const item = healthHistory[cookies.get("userId")]
    .filter(card=>card.id===modalId.cardId)[0].items
    .filter(item=>(
      item.id===modalId.itemId && 
      (item.type === "health_photo" || item.type === "vaccine" || item.type === "prescription" || item.type === "exam")
    ))[0]

  const [ stateFile, setStateFile ] = React.useState(item.filename)

  let heading = ""
  let imgUrl = ""
  let deleteUrl = ""
  if (item.type === "health_photo") {
    heading = "Fotos"
    imgUrl = `${backendUrl}/health/photo/internal`
    deleteUrl = "/health/photo"
  } else if (item.type === "vaccine") {
    heading = "Vacinas"
    imgUrl = `${backendUrl}/document/internal`
    deleteUrl = "/vaccine"
  } else if (item.type === "prescription") {
    heading = "Prescrições"
    imgUrl = `${backendUrl}/document/internal`
    deleteUrl = "/prescription"
  } else if (item.type === "exam") {
    heading = "Exames"
    imgUrl = `${backendUrl}/document/internal`
    deleteUrl = "/exam"
  }

  const deleteItemFromBackend = async (filename) => {

    await deleteDataFromApi(
      {name: filename, id: modalId.cardId}, 
      deleteUrl, auth, cookies
    )
  }

  const deleteItem = (filename) => {
    deleteItemFromBackend(filename)

    const updatedItems = healthHistory[+cookies.get("userId")]
      .filter(card=>card.id===modalId.cardId)[0].items
      .filter(item=>item.id===modalId.itemId)[0].filename
      .filter(name=>name!==filename)

    const updatedFolderWithDeletion = healthHistory[+cookies.get("userId")]
     .filter(card=>card.id===modalId.cardId)[0].items
     .filter(item=>item.id===modalId.itemId)[0]
    
    updatedFolderWithDeletion.filename = updatedItems

    setStateFile(updatedItems)
    //updatedFolderWithDeletion.items = updatedItems

    if (updatedItems.length === 0) {
      setModalState(false)
    }
  }
  
  const download = e => {
    console.log(e.target.href);
    fetch(e.target.href, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url)
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
 
  if (!item) {
    return null
  }

  return (
    <div className="modal-background">
      <div className="modal-container">

        <div className="modal-close-button">
          <button className="svg-btn" onClick={()=>setModalState(false)} buttonType='button'>
            <img className="health-history-small-menu-nav health-history-inv-svg" 
                src={closeMenuLogo} alt="Button to toggle menu" />
          </button>
        </div>

        <HealthHistoryEditableTitle item={item} cardId={modalId.cardId}/>
        
        {stateFile.map((filename, index) => (
          <div key={filename}>
            <img className="health-history-image-thumbnail" 
            key={index} src={`${imgUrl}/${filename.split('.')[0]}`} />
            <div style={{ display: "flex" }} className="bottom-margin-s">
              <a className="btn-tertiary modal-teriary-button right-margin-xs" href={`${imgUrl}/${filename.split('.')[0]}`} download onClick={e => download(e)} target = "_blank">
                  Download
              </a>
              <Button type="tertiary modal-teriary-button" onClick={()=>{deleteItem(filename)}}>
                Excluir 
              </Button>
            </div>
          </div>
        ))}

      </div>
    </div>
  )
}
