import React from 'react'

export default function HealthHistoryButton({ icon, breakpoint, children, onClick }) {
  
  return (
    <button className={(breakpoint) ? "health-history-button" : "health-history-button-smaller"} onClick={onClick}>
      { icon && <img className={(breakpoint) ? "button-icon" : "button-icon-smaller"} src={icon} alt="search button"/> }
      <p className={(breakpoint) ? "health-history-button-typo" : "health-history-button-typo-smaller"}>
      {children}
      </p>
    </button>
  )
}
