import React from 'react'
import AuthContext from '../context/AuthProvider';
import Button from './Button';
import EditIcon from '../images/edit.svg'
import Attention from "../images/sun.svg"
import HighlightedMessage from './HighlightedMessage';
import { putRequestToApi, registerAction } from '../api';
import { useNavigate } from "react-router-dom"
import { PortalContext } from '../AuthenticatedUser';

export default function HealthHistoryEditableTitle({ item, cardId }) {

  const { auth, setAuth, cookies } = React.useContext(AuthContext)
  const { healthHistory } = React.useContext(PortalContext);

  const [ editTitle, setEditTitle ] = React.useState(false)
  const [ error, setError ] = React.useState(null)
  const [ statusChangeTitle, setStatusChangeTitle ] = React.useState('idle')

  const [formData, setFormData] = React.useState({
    title: item.group_name
  })
  
  const navigate = useNavigate()

  async function submitEdition() {

    setError(null)

    if (formData.title === "") {
      setError("O título não pode ficar em branco")
      return
    }


    setStatusChangeTitle("submitting")

    try {

      const requestBody = {
        user_id: +cookies.get("userId"), 
        updated_name: formData.title,
        old_name: item.group_name,
        group_type: item.type,
        folder_id: cardId,
      }

      const data = await putRequestToApi(requestBody, "/health/group", auth)

      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      }))

      let updatedItems = healthHistory[cookies.get("userId")]
        .filter(card=>card.id===cardId)[0].items

      updatedItems = updatedItems.map(updatedItem => {
        if (updatedItem.id === item.id) {
          updatedItem.group_name = formData.title
          return updatedItem
        } else {
          return updatedItem
        }
      })

      const updatedFolderWithDeletion = healthHistory[cookies.get("userId")]
        .filter(card=>card.id===cardId)[0]

      updatedFolderWithDeletion.items = updatedItems
    
      setStatusChangeTitle("idle")
      setEditTitle(false)

    } catch (err) {

      console.log(err)
      console.log("Error loading data")
      navigate('/loginrequired')

    } 
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    submitEdition()

    registerAction(+cookies.get("userId"), "healthHistoryTitleChanged", auth, cookies)
  }
  
  function handleChange(e) {
    const {name, value} = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  return (
    (!editTitle) ?
      <div style={{alignItems: "center", marginBottom: "16px"}}>
        <div style={{display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
          <p className="intra-page-title" style={{textAlign: "left"}}>
            {item.group_name} 
          </p>
          <button onClick={()=>{setEditTitle(prev=>!prev)}} className='carousel-btn modal-teriary-button'>
            <img className="" src={EditIcon}/>
          </button>
        </div>
      </div> : 
      <div>
        <form onSubmit={handleSubmit}>
          {(error) ?
            <div className="bottom-margin-m">
              <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />
            </div> :
            null
          }
          <div className="bottom-margin-xs">
            <p className="body">Alteração de título</p>
            <textarea id="title" name="title" className="profile-question-form form-full-width shorter-form" type="text" 
                placeholder="Adicione novo título" onChange={handleChange} value={formData.title}/>
          </div>
          <div style={{ display: "flex", flexDirection: 'row', marginTop: "4px", marginBottom: "16px", gap: "8px" }}>
            <Button type="primary">
              {(statusChangeTitle==="idle") ? "Alterar título" : "Alterando..."} 
            </Button>
            <Button type="tertiary modal-teriary-button" buttonType="button" onClick={()=>setEditTitle(false)}>
              Cancelar
            </Button>
          </div>
        </form>
      </div>

  )
}
