import React from "react"
import Button from "../components/Button"

export default function Footer({tabletBreakpoint, laptopBreakpoint, border}) {
  return (
    <div className={(border) ? `footer ${border}` : "footer"}>
      <div className={(tabletBreakpoint) ? 
        (laptopBreakpoint) ? `footer-container-laptop ${border}` : `footer-container-tablet ${border}` : 
        `footer-container ${border}`}>
        <Button type="secondary top-margin-xxl bottom-margin-m" link={true} to="/about">Quem Somos</Button>
        <div>  
          <h3 className="heading3 bottom-margin-s">Onde Estamos</h3>
          <p className="body bottom-margin-s">Rua Duque de Caxias 1364, Porto Alegre/RS<br/>
          Email: contato@florencehealth.com.br</p>
          <p className="body bottom-padding-xxl">&copy; 2025 Florence Health. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  )
}
