import React from 'react'
import Button from './Button'

export default function Header2({ tabletBreakpoint, laptopBreakpoint}) {


  return (
    <div className={(tabletBreakpoint) ? 
      ((laptopBreakpoint) ? "header2-background-laptop top-padding-m" : "header2-background top-padding-m") 
      : "header2-background top-padding-s"}>
      <div className={(tabletBreakpoint) ? 
        (laptopBreakpoint) ? "container-simple-laptop header2" : "container-simple-tablet header2" : 
        "container-simple header2"}>

        <img className={(tabletBreakpoint) ? 
          ((laptopBreakpoint) ? "small-logo-nav-no-border-laptop" : "small-logo-nav-no-border-tablet") : 
          "small-logo-nav-no-border" }
          src={require("../images/florence-darker.png")} alt="Florence logo"/>

        {(tabletBreakpoint) ?
        <div className="header2-buttons">
          <Button type="tertiary-header2" link to="/about">Quem somos</Button>
          <Button type="tertiary-header2" link to="/registration">Cadastro</Button>
        </div> :
        <div className="header2-buttons">
          <Button type="tertiary-header2" link to="/about">Quem somos</Button>
          <Button type="tertiary-header2" link to="/registration">Cadastro</Button>
          <Button type="secondary-header2" link to="/loginrequired">Entrar</Button>
        </div>
        }
      </div>
    </div>
  )
}
