import React from 'react'
import Button from './Button'

export default function HomeHero({ tabletBreakpoint, laptopBreakpoint}) {

  return (
    <div className={(tabletBreakpoint) ? 
      (laptopBreakpoint) ? "home-hero-laptop" : "home-hero-tablet" : 
      "home-hero-phone"}>
      <img className="home-hero-image" 
        src={require("../images/home-hero-1.jpg")} alt="home image"/>
      <div className="home-hero-overlay">
        <div className={(tabletBreakpoint) ? 
          (laptopBreakpoint) ? "hero-content-laptop" : "hero-content-tablet" : 
          "hero-content-phone "}>
          <p className={(laptopBreakpoint) ? 
            "florence-title home-hero-text-laptop top-margin-m" : "intra-page-title home-hero-text"}>
            A Florence ajuda você a cuidar de sua saúde
          </p>
          {(tabletBreakpoint && !laptopBreakpoint) && 
            <Button type="primary-header2" link to="/loginrequired">Entrar</Button>}
          {(laptopBreakpoint) && 
            <Button type="primary-header2-larger" link to="/loginrequired">Entrar</Button>}
        </div>

      </div>
    </div>
  )
}
