import React from 'react'

export default function AppStoreBadge({ icon, secondaryText, storeName, link }) {

  return (
    <a href={link} className="app-store-badge-font app-badge-container">    
      <div className="app-store-badge">
        <div>
          <img className="app-store-icon" src={icon} alt="app store icon"/>
        </div>

        <div className="app-store-text">
          <p className="body app-store-badge-font">{secondaryText}</p>
          <p className="body app-store-badge-font"><strong>{storeName}</strong></p>
        </div>
      </div>
    </a>
  )
}
