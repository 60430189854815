import React from 'react';
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import ProductSection from "../components/ProductSection"
import Carousel from "../components/Carousel"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Button from "../components/Button"
import LoginBlock from "../components/LoginBlock"
import AuthContext from '../context/AuthProvider'
import HomeThumbnails from "../components/Forum/HomeThumbnails"
import Header2 from '../components/Header2';
import HomeHero from '../components/HomeHero';
import AppBadges from '../components/AppBadges';

export default function Home() {
  
  const { auth, cookies } = React.useContext(AuthContext)
  const navigate = useNavigate()

  const laptopBreakpoint = useMediaQuery({ query: '(min-width: 1024px)' })
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
 
  React.useEffect(() => {
    if (auth.accessToken || cookies.get("accessToken")) {
      if (cookies.get("userId")) {
        navigate("/profile")
      } else {
        navigate("family")
      }
    }
  }, [])
  
  return (
    <>
      <Header2 tabletBreakpoint={tabletBreakpoint} laptopBreakpoint={laptopBreakpoint}/>

      <HomeHero tabletBreakpoint={tabletBreakpoint} laptopBreakpoint={laptopBreakpoint}/>

      <div style={{backgroundColor: "#DADFF2"}}>
        <div className={"container-simple"}>

          <div className={(tabletBreakpoint || laptopBreakpoint) ? "vertical-padding-xxl" : "vertical-padding-xxl"} 
            style={{
              style: "flex", 
              alignItems: "center", 
              justifyItems: "center", 
              textAlign: "center"
            }}>
            <h1 className="intra-page-title home-centered-text bottom-margin-l">Comece a usar agora</h1>
            <AppBadges center/>
          </div>

        </div>
      </div>

      <div className={(tabletBreakpoint) ? 
        (laptopBreakpoint) ? "container-simple-laptop" : "container-simple-tablet": 
        "container-simple"}>
        <section>

          <ProductSection 
            title="Você escolhe seus hábitos" 
            text="Quem escolhe quais hábitos desenvolver é você, e a Florence te ajuda no caminho! Utilize nossas ferramentas para se exercitar e se alimentar melhor. Nosso papel é ajudar você a atingir seus objetivos"
            img="first-image-home.png"
            breakpoint={tabletBreakpoint}
            laptopBreakpoint={laptopBreakpoint}
            left
          />

          <ProductSection 
            title="A Florence se encaixa na sua rotina, e não o contrário" 
            text="Você não precisa reconstruir seu dia-a-dia com rotinas complicadas, como pesar sua comida. Utilizar a Florence é fácil e conveniente, e pode ser tão simples quanto tirar uma foto da sua comida ou atividade física"
            img="second-1-image-home.png"
            secondImg="second-2-image-home.png"
            breakpoint={tabletBreakpoint}
            laptopBreakpoint={laptopBreakpoint}
          />

          <ProductSection 
            title="Mantenha a motivação" 
            text="Transformamos a melhora de hábitos em um jogo, onde você é recompensado pela sua evolução a cada ação que você faz"
            img="third-image-home.png"
            breakpoint={tabletBreakpoint}
            laptopBreakpoint={laptopBreakpoint}
            left
          />
          
          <ProductSection 
            title="Seus objetivos são só seus" 
            text="Entendemos que cada pessoa tem suas diferenças e seus próprios objetivos, e a Florence se molda a você, com sugestões para facilitar sua jornada e mensagens para te ajudar a corrigir a rota quando necessário"
            img="fourth-image-home.png"
            breakpoint={tabletBreakpoint}
            laptopBreakpoint={laptopBreakpoint}
          />
          
          <ProductSection 
            title="Tenha seus histórico de saúde em dia" 
            text="A Florence é uma ferramenta fácil e segura para você guardar seus dados de saúde e montar seu histórico ao longo do tempo. Você pode enviar seus dados para seu médico e familiares quando necessário, e nunca perde ou esquece nenhuma informação importante. Ter seu histórico de saúde em dia é como um seguro, é melhor ter!"
            img="fifth-image-home.png"
            breakpoint={tabletBreakpoint}
            laptopBreakpoint={laptopBreakpoint}
            left
          />

        </section>
      </div>

      <Footer tabletBreakpoint={tabletBreakpoint} laptopBreakpoint={laptopBreakpoint} border="footer-no-right-border"/>
    </>
  )
}
