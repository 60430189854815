import React from 'react'
import BullseyeGray from "../images/bullseye-gray.svg"
import BullseyeBlue from "../images/bullseye-blue-small.svg"
import CheckboxGray from "../images/checkbox.svg"
import CheckboxBlue from "../images/checkbox-blue.svg"
import Fisherman from '../images/sport-fisherman.svg'
import FoodIcon from "../images/meal-black.svg"
import RunIcon from "../images/running-black.svg"
import FoodWhiteIcon from "../images/meal.svg"
import RunWhiteIcon from "../images/running.svg"
import Lazy from '../images/lazy-animal.svg'
import FoodThumbnail from "./Eating/FoodThumbnail"
import { useNavigate } from 'react-router-dom'
import { format, startOfMonth, endOfMonth, eachDayOfInterval, getDay, isToday, isAfter, addMonths } from "date-fns"
import ExerciseThumbnail from './Exercise/ExerciseThumbnail'
import Button from './Button'
import PlusIcon from '../images/plus.svg'
import PlusBlueIcon from '../images/plus-blue.svg'
import { deleteDataFromApi, registerAction } from '../api'
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import PlanThumbnail from './Eating/PlanThumbnail'
import Tag from './Tag'

const WEEKDAYS = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]

function createDate(days) {
        var date = new Date(); 
        date.setDate(date.getDate() - days);
        //date.setMonth(date.getMonth() + months);
        //date.setFullYear(date.getFullYear() + years);
        return date;    
}

export default function LastActivities({ tabletBreakpoint=false }) {
 
  const [addButtons, setAddButtons] = React.useState(false)
  const [seeGoals, setSeeGoals] = React.useState(false)
  const [seePlan, setSeePlan] = React.useState(false)
  const [filter, setFilter] = React.useState(getDay(new Date, "dd"))
  const [filterCompleteDay, setFilterCompleteDay] = React.useState(format(new Date, "dd/MM/yyyy"))
  const [filterCompleteDayUS, setFilterCompleteDayUS] = React.useState(format(new Date, "MM/dd/yyyy"))
  
  const [selection, setSelection] = React.useState("all")

  const { auth, cookies } = React.useContext(AuthContext);
  
  const {
    setExerciseHistory,
    setMealsHistory,
    exerciseHistory,
    mealsHistory,
    setExercise,
    setEating,
    exercise,
    eating,
    evolutionBar,
    setEvolutionBar
  } = React.useContext(PortalContext);

  const weekdays = []
  const monthdays = []
  const completedays = []
  const completedaysUS = []
  let startDay = new Date()
  let currentDay = new Date()

  const navigate = useNavigate()

   // seven days prior than the finish day
  for (let i=0; i<7; i+=1) {
    //startDay.setDate(currentDay.getDate() - i)
    startDay = createDate(i)
    
    weekdays.unshift(getDay(startDay))
    monthdays.unshift(format(startDay, "dd"))
    completedays.unshift(format(startDay, "dd/MM/yyyy"))
    completedaysUS.unshift(format(startDay, "MM/dd/yyyy"))
  }

  const exercisesFiltered = exerciseHistory.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)
  const mealsHistoryFiltered = mealsHistory.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)
  
  let accumulatedCalories = 0
  let accumulatedProteins = 0

  let calendarItemsAfterFilter = null
  if (mealsHistory) {
    calendarItemsAfterFilter = mealsHistoryFiltered.filter(item => {
      return filterCompleteDay.includes(format(item.date, "dd/MM/yyyy"))
    })
    calendarItemsAfterFilter.forEach(item => {
      if (item?.calories) {
        accumulatedCalories += +item.calories
        accumulatedProteins += +item.proteins
      }
    })
  }
  let exerciseCalendarItemsAfterFilter = null
  if (exerciseHistory) {
    exerciseCalendarItemsAfterFilter = exercisesFiltered.filter(item => {
      return filterCompleteDay.includes(format(item.date, "dd/MM/yyyy"))
    })
  }
  
  const removeFood = (food) => {
    console.log(`removing food ${food.id}`)
    deleteDataFromApi({"item_id": +food.id, "id": +cookies.get("userId")}, 'food', auth, cookies)
    setMealsHistory(prev => prev.filter(item => item.id !== food.id))
        
    const historyUpdated = eating.history.filter(item => item.id !== food.id)
    setEating(prev => ({...prev, history: historyUpdated}))
    
    const updatedCount = evolutionBar[format(food.date, "MM/dd/yyyy")]
    if (food.grade.toUpperCase() === "HEALTHY") {
      updatedCount.healthy -= 1
      updatedCount.total -= 1
    } else if (food.grade.toUpperCase() === "ATTENTION") {
      updatedCount.attention -= 1
      updatedCount.total -= 1
    } else if (food.grade.toUpperCase() === "SUPER HEALTHY") {
      updatedCount.super_healthy -= 1
      updatedCount.total -= 1
    } 
    setEvolutionBar(prev => ({...prev, [format(food.date, "MM/dd/yyyy")]: updatedCount }))
  }
  
  const removeExercise = (exerciseToRemove) => {
    console.log(`removing exercise ${exerciseToRemove.id}`)
    deleteDataFromApi({"item_id": +exerciseToRemove.id, "id": +cookies.get("userId")}, 'exercise', auth, cookies)
    setExerciseHistory(prev => prev.filter(item => item.id !== exerciseToRemove.id))

    const historyUpdated = exercise.history.filter(item => item.id !== exerciseToRemove.id)
    setExercise(prev => ({...prev, history: historyUpdated}))

    const updatedCount = evolutionBar[format(exerciseToRemove.date, "MM/dd/yyyy")]
      
    updatedCount.super_healthy -= 1
    updatedCount.total -= 1

    setEvolutionBar(prev => ({...prev, [format(exerciseToRemove.date, "MM/dd/yyyy")]: updatedCount }))
  }

  const filterDayActivities = (day, i) => {
    setFilter(day)
    setFilterCompleteDay(completedays.at(i))
    setFilterCompleteDayUS(completedaysUS.at(i))
    registerAction(+cookies.get("userId"), "filterdaylastactivities", auth, cookies)
  }

  const addNewEntry = (type) => {
    if (type === "exercise") {
      navigate("/exercise/submit", {state: {date: new Date(filterCompleteDayUS)}})
    } else if (type === "eating") {
      navigate("/eating/submit", {state: {date: new Date(filterCompleteDayUS)}})
    }   
  }

  const weekActivities = weekdays.map((day, i) => {
  return (
    <button key={day} className={(filter === day) ? 
      "last-activity-day-selected top-margin-xs" : "last-activity-day top-margin-xs"}
      onClick={()=>{filterDayActivities(day, i)}}
    >
      <p className={(filter === day) ? 
          "body last-activity-day-name-selected" : "body last-activity-day-name"}>{WEEKDAYS[day]}</p>
      <p className={(filter === day) ? 
          "body last-activity-day-number-selected bottom-margin-xs" :
          "body last-activity-day-number bottom-margin-xs"}>{monthdays.at(i)}</p>
      <div>
        {(mealsHistoryFiltered.filter(item => (
          format(item.date, "dd/MM/yyyy") === completedays.at(i))).length > 0
        ) ? 
          <img src={(filter === day) ? FoodWhiteIcon : FoodIcon} className="last-activity-icon bottom-margin-xs" alt="Botão para portal de alimentação"/>
          : null
        }
      </div>
      <div>
        {(exercisesFiltered.filter(item => (
            format(item.date, "dd/MM/yyyy") === completedays.at(i))).length > 0
          ) ? 
            <img src={(filter === day) ? RunWhiteIcon : RunIcon} className="last-activity-icon" alt="Botão para portal de alimentação"/>
            : null
        }
      </div>

    </button>
  )})


  
  const clickAddButton = () => {
    setAddButtons(prev => !prev)
    setSeePlan(false)
    setSeeGoals(false)
    registerAction(+cookies.get("userId"), `filterlastactivitiesAddButton`, auth, cookies)
  }

  const clickSeeGoals = () => {
    setSeeGoals(prev => !prev)
    setSeePlan(false)
    setAddButtons(false)
    registerAction(+cookies.get("userId"), `filterlastactivitiesSeeGoals`, auth, cookies)
  }

  const clickSeePlans = () => {
    setSeePlan(prev => !prev)
    setSeeGoals(false)
    setAddButtons(false)
    registerAction(+cookies.get("userId"), `filterlastactivitiesSeePlans`, auth, cookies)
  }

  const filterActivities = (activity) => {
    setSelection(activity)
    registerAction(+cookies.get("userId"), `filterlastactivities${activity}`, auth, cookies)
  }

  return (
    <div className="single-object-centered">
      <div className={(tabletBreakpoint) ? "last-activities-card-tablet" : "last-activities-card"}>
        <div className="editable-card-title">
          <div className="center-title">
            <p className="heading5">Últimas atividades</p>
          </div>
          <div className="row-buttons-centered">
            <button className='svg-btn' onClick={()=>{clickSeeGoals()}}>
              {(!seeGoals) ?
                <div>
                  <img className="" src={BullseyeGray} alt="See goals and objectives"/> 
                  <p className="secondary-text-smaller">Objetivos</p>
                </div> :
                <div>
                  <img className="" src={BullseyeBlue} alt="See goals and objectives"/>
                  <p className="secondary-text-smaller brand-color">Objetivos</p>
                </div>
              }
                
            </button> 
            <button className='svg-btn checkbox-svg left-margin-xxs' onClick={()=>{clickSeePlans()}}>
              {(!seePlan) ?
                <div>
                  <img className="right-margin-xxs" src={CheckboxGray} alt="See weekly plan"/> 
                  <p className="secondary-text-smaller">Planos</p>
                </div> :
                <div>
                  <img className="right-margin-xxs" src={CheckboxBlue} alt="See weekly plan"/>
                  <p className="secondary-text-smaller brand-color">Planos</p>
                </div>
              }
            </button> 
          </div>
        </div>
        <div className="datepicker-calendar">
          {weekActivities}
        </div>

        {(addButtons) ?
        <>
        <div>
          <Button type="secondary top-margin-xs" onClick={()=>{addNewEntry('eating')}}>
            Adicionar refeição
          </Button>
        </div>
        <div>
          <Button type="secondary top-margin-xs" onClick={()=>{addNewEntry('exercise')}}>
            Adicionar exercício
          </Button>
        </div>
        </> : null }

        {(seeGoals) ?
        <div className="bottom-margin-m div-no-shadow">
          <p className="body"><strong>Objetivos </strong>{(eating.goals.length !== 0) ? <Button type="tertiary left-margin-xxs" to="/profile/objectives" link={true}> Ir para página</Button>: null}</p>
          {
            (eating && eating.goals.length > 0) ? eating.goals.map((item, i) => (
              <div key={i} className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
                <div className='editable-item-for-card-header'>
                  {(item.title && item.title !== "") ? 
                  <p key={i} className="body"><strong>{item.title}: </strong>{item.content}</p> : 
                  <p key={i} className="body">{item.content}</p> } 
                </div>
              </div>
            )) : null 
          }
          { (eating && eating.goals.length === 0) ?
            (
              <div className="submit-column vertical-margin-m div-no-shadow">
                <img className="submission-image-small" src={Fisherman} alt="No goals yet"/>
                <p className="body secondary-color top-margin-s">Nenhum objetivo cadastrado</p>
                <Button type="tertiary top-margin-xxs" link={true} to="/profile/objectives">Ir para objetivos</Button>
              </div>
            ) : null
          }
        </div>

        : null }
        
        {(seePlan) ?
        <div className="bottom-margin-m div-no-shadow">
          <p className="body"><strong>Plano semanal </strong>{(eating.plan.length !== 0) ? <Button type="tertiary left-margin-xxs" to="/profile/objectives" link={true}>Ir para página</Button>: null}</p>
          {
            (eating && eating.plan.length > 0) ? eating.plan.map((item, i) => (
              <PlanThumbnail item={item} i={i} icons={false} />
            )) : null
          }
          { (eating && eating.plan.length === 0) ?
            (
              <div className="submit-column vertical-margin-m div-no-shadow">
                <img className="submission-image-small" src={Fisherman} alt="No goals yet"/>
                <p className="body secondary-color top-margin-s">Sem planos cadastrados</p>
                <Button type="tertiary top-margin-xxs" link={true} to="/profile/objectives">Ir para planejamento</Button>
              </div>
            ) : null
          }
        </div>

        : null }

        {(calendarItemsAfterFilter.length > 0 || exerciseCalendarItemsAfterFilter.length > 0) ?
        <div className="top-margin-s">

          <div className="tags-horizontal-menu horizontal-small-gap div-no-shadow bottom-margin-s">
            <button className="svg-btn" onClick={()=>{filterActivities("all")}}>
              <Tag color={(selection === "all") ? "selected tag-small" : "blue"}>Tudo</Tag>
            </button>

            <button className="svg-btn" onClick={()=>{filterActivities("exercise")}}>
              <Tag color={(selection === "exercise") ? "selected tag-small" : "blue"}>Exercício</Tag>
            </button>

            <button className="svg-btn" onClick={()=>{filterActivities("super healthy")}}>
              <Tag color={(selection === "super healthy") ? "selected tag-small" : "blue"}>Super saudável</Tag>
            </button>

            <button className="svg-btn" onClick={()=>{filterActivities("healthy")}}>
              <Tag color={(selection === "healthy") ? "selected tag-small" : "blue"}>Saudável</Tag>
            </button>

            <button className="svg-btn" onClick={()=>{filterActivities("attention")}}>
              <Tag color={(selection === "attention") ? "selected tag-small" : "blue"}>Atenção</Tag>
            </button>
          </div>

          {(accumulatedCalories > 0 && accumulatedProteins > 0) ? 
            <div>
              <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
                <div className='editable-item-for-card-header'>
                  <p className="body"><strong>Consumo de calorias: </strong>{Math.round(accumulatedCalories)} cal</p> 
                </div>
              </div>
              <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
                <div className='editable-item-for-card-header'>
                  <p className="body"><strong>Consumo de proteínas: </strong>{Math.round(accumulatedProteins)} g</p> 
                </div>
              </div>
            </div>
            : null 
          }
          {(selection === "all" || selection === "exercise") ? exerciseCalendarItemsAfterFilter.map(exercise => (
            <ExerciseThumbnail key={exercise.id} exercise={exercise} onRemove={() => removeExercise(exercise)} icons={true}/>
          )) : null }
          {(selection === "all") ? calendarItemsAfterFilter.map(food => (
            <FoodThumbnail key={food.id} food={food} onRemove={() => removeFood(food)} icons={true}/>
          )) : null}
          {(selection !== "all" && selection !== "exercise") ? calendarItemsAfterFilter.map(food => {
              if (selection === food.grade) {
                return <FoodThumbnail key={food.id} food={food} onRemove={() => removeFood(food)} icons={true}/>
              } else {
                return null
              }
            }
          ) : null}
        </div> : 
        <div className="submit-column bottom-margin-m">
          <img className="submission-image" src={Lazy} alt="No activity yet"/>
          <p className="body secondary-color">Nenhuma atividade ainda</p>
        </div>
        }
      </div>
    </div>
  )
}

