import React from 'react'
import closeMenuLogo from "../images/x-solid-black.svg"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import { postRequestToApi } from '../api'
import HealthHistoryTextField from './HealthHistoryTextField'

export default function HealthHistoryNewDescriptionModal({ modalId, setModalState }) {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { setHealthHistory, healthHistory } = React.useContext(PortalContext);
  
  const card = healthHistory[cookies.get("userId")]
    .filter(card=>card.id===modalId.cardId)[0].items

  const [folderFormData, setFolderFormData] = React.useState({
    text: ""
  })

  const [error, setError] = React.useState(null)
  const [status, setStatus] = React.useState('idle')

  const userRef = React.useRef()

  React.useEffect(() => {
    if (userRef.current) {
      userRef.current.focus()
    }
  }, []);
 
  const handleSubmit = (e) => {
    e.preventDefault()

    if (folderFormData.text ) {

      setError(null)

      async function trySubmitingDescription(folderFormData) {
        try {
          setStatus("submitting")

          const requestBody = {
            id: +cookies.get("userId"),  
            parents: [modalId.cardId],
            text: folderFormData.text
          }

          const data = await postRequestToApi(requestBody, "/health/description", auth, cookies)  // here call the api

          setAuth(prev => ({...prev, ...data.tokens}))

          // here need to update the right things

          card.unshift({
            date_created: new Date(),
            id: data.id,
            text: folderFormData.text,
            type: "description",
          })

        } catch(err) {
          setError(err.message)
        } finally {         
          setStatus("idle")
          setModalState(false)
        }
      }
      
      trySubmitingDescription(folderFormData)

    } else {
      setError("O texto da descrição não pode estar vazio")
    }
  }
 
  return (
    <div className="modal-background">
      <div className="modal-container">

        <div className="modal-close-button">
          <button className="svg-btn" onClick={()=>setModalState(false)} buttonType='button'>
            <img className="health-history-small-menu-nav health-history-inv-svg" 
                src={closeMenuLogo} alt="Button to toggle menu" />
          </button>
        </div>

        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <p className="intra-page-title bottom-margin-xs">Adicionar descrição</p>
        </div>
        
        <HealthHistoryTextField
          error={error}
          handleSubmit={handleSubmit}
          folderFormData={folderFormData}
          setFolderFormData={setFolderFormData}
          status={status}
          setEdit={setModalState} />

      </div>
    </div>
  )
}
