import { format } from 'date-fns'
import React from 'react'
import Button from './Button'
import PaperClip from '../images/paper-clip.svg'

export default function HealthHistoryPdf({ item, cardId, setModal, setModalId}) {

  const openModal = (internal_filename) => {
    setModal(prev => !prev)
    setModalId({ cardId, itemId: item.id, type: item.type, filename: internal_filename })
  }

  let heading = ""
  if (item.type === "vaccine") {
    heading = "Vacinas"
  } else if (item.type === "prescription") {
    heading = "Prescrições"
  } else if (item.type === "exam") {
    heading = "Exames"
  }
  return (
    <div className="card-element" style={{cursor: "auto"}}>
      <p className="body"><strong>{item.group_name}</strong></p>
      {item.external_filename.map((external_filename, index) => (
        <div style={{display: "flex", alignItems: "center", gap: "0.5rem"}} className="vertical-margin-xs">
          <img className={"button-icon-smaller"} src={PaperClip} alt="Document annexed"/>
          <Button type="tertiary tertiary-background-white health-history-pdf-name" 
            key={external_filename} onClick={()=>openModal(item.filename[index])}>{external_filename}</Button>
        </div>
      ))}
    </div>
  )
}
