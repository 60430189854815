import React from 'react'

export default function HealthHistoryHorizontalBoard({ children }) {

  return (
    <div className="health-history-horizontal-board">
      { children }

      <div className="evolution-columns">
        <div style={{ height: "20px", width: "16px"}}></div>
      </div>
    </div>
  )
}
