import { format } from 'date-fns'
import React from 'react'

export default function HealthHistoryDescription({ item, cardId, setModal, setModalId }) {

  const openModal = () => {
    setModal(prev => !prev)
    setModalId({ cardId, itemId: item.id, type: "description" })
  }

  return (
      <div className="card-element" onClick={()=>openModal()}>
        <p className="body"><strong>Descrição {format(item.date_created, 'dd/MM')}</strong></p>
        <p className="body health-history-description-thumbnail ">
          {item.text}
        </p>
      </div>
  )
}
