import React from 'react'
import { useMediaQuery } from 'react-responsive'
import HealthHistoryButton from '../components/HealthHistoryButton'
import HealthHistoryHorizontalBoard from '../components/HealthHistoryHorizontalBoard'
import HealthHistoryVerticalCardContainer from '../components/HealthHistoryVerticalCardContainer'
import { PortalContext } from '../AuthenticatedUser'
import AuthContext from '../context/AuthProvider'
import { useNavigate, Link } from 'react-router-dom'
import HealthHistoryToggleMenu from '../components/HealthHistoryToggleMenu'
import HealthHistoryAddNewStory from '../components/HealthHistoryAddNewStory'
import HealthHistoryDescription from '../components/HealthHistoryDecription'
import HealthHistoryPhoto from '../components/HealthHistoryPhoto'
import HealthHistoryPdf from '../components/HealthHistoryPdf'
import HealthHistoryDescriptionModal from '../components/HealthHistoryDescriptionModal'
import HealthHistoryPhotoModal from '../components/HealthHistoryPhotoModal'
import HealthHistoryPdfModal from '../components/HealthHistoryPdfModal'
import HealthHistoryNewDescriptionModal from '../components/HealthHistoryNewDescriptionModal'
import HealthHistoryAddFiles from '../components/HealthHistoryAddFiles'
import HealthHistoryPrescriptionText from '../components/HealthHistoryPrescriptionText'
import HealthHistoryPrescriptionTextModal from '../components/HealthHistoryPrescriptionTextModal'
import Button from '../components/Button'
import ChevronUp3 from '../images/chevron-up3.svg'
import ChevronDown3 from '../images/chevron-down3.svg'
import HealthHistorySubjectMenu from '../components/HealthHistorySubjectMenu'
import HealthHistorySubjectSelectionMenu from '../components/HealthHistorySubjectSelectionMenu'

export default function UserHealthHistory() {
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { cookies } = React.useContext(AuthContext);

  const [ subjectId, setSubjectId ] = React.useState(-1)
  const [ headerTitle, setHeaderTitle ] = React.useState("Ver todos")

  const [ subjectMenu, setSubjectMenu ] = React.useState(false)

  const [ createNewHistory, setCreateNewHistory ] = React.useState(false)
  const [ cardModal, setCardModal ] = React.useState(false)
  const [ modalId, setModalId ] = React.useState({ cardId: null, itemId: null, type: null, filename: null })

  const { 
    healthHistory,
    loadHealthHistoryDataFromAPI
  } = React.useContext(PortalContext);
  
  React.useEffect(() => {

    if (!cookies.get("userId")) {
      navigate("/family")
    } else {
      if (!healthHistory[cookies.get("userId")] || healthHistory[cookies.get("userId")].length === 0) {
        loadHealthHistoryDataFromAPI()
      }
    }

  }, []);

  const navigate = useNavigate()
  
  let userBoard = null

  console.log(healthHistory)

  if (!healthHistory?.[cookies.get("userId")]) {
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
    )
  }

  const chooseSubject = (chosenId) => {
    setSubjectMenu(false)
    if (chosenId === 0) {
      setSubjectId(0)
      setHeaderTitle("Geral")
    } else if (chosenId === 1) {
      setSubjectId(1)
      setHeaderTitle("Crônicos")
    } else if (chosenId === 2) {
      setSubjectId(2)
      setHeaderTitle("Agudos")
    } else {
      setSubjectId(-1)
      setHeaderTitle("Ver todos")
    }
  }

  if (subjectId === null) {
    return (
      <HealthHistorySubjectSelectionMenu chooseSubject={chooseSubject} />
    )
  }

  let filterFolders = []
  if (subjectId === -1) {
    filterFolders = healthHistory[cookies.get("userId")]  
  } else {
    filterFolders = healthHistory[cookies.get("userId")].filter(item => item.subject_id === subjectId)
  }

  if (filterFolders.length == 0) {
    userBoard = (
      <div className="health-history-new-card-button" onClick={()=>setCreateNewHistory(prev=>!prev)}>
        <p className="heading5 health-history-card-title">
          Criar novo ficheiro
        </p>
      </div>
    )
  } else {
    userBoard = filterFolders.map((item) => {
      return (
        <HealthHistoryVerticalCardContainer 
          title={item.name} 
          key={item.id} 
          itemId={item.id} 
          setCardModal={setCardModal}
          setModalId={setModalId}
        >
          {item.items.map((element, index) => {
            if (element.type !== "description" && element.extension !== "text" && element.filename.length === 0) {
              return null
            }
            return(
              <div key={index}>
                {(element.type === "description") && 
                  <HealthHistoryDescription item={element} cardId={item.id} setModal={setCardModal} setModalId={setModalId} />}
                {(element.type === "health_photo" && element.filename.length > 0) && 
                  <HealthHistoryPhoto item={element} cardId={item.id} setModal={setCardModal} setModalId={setModalId}  /> }
                {(element.type === "vaccine" && element.filename.length > 0 && element.extension === "jpeg") && 
                  <HealthHistoryPhoto item={element} cardId={item.id} setModal={setCardModal} setModalId={setModalId}  /> }
                {(element.type === "prescription" && element.extension === "text") && 
                  <HealthHistoryPrescriptionText item={element} cardId={item.id} setModal={setCardModal} setModalId={setModalId} />}
                {(element.type === "prescription" && element.filename.length > 0 && element.extension === "jpeg") && 
                  <HealthHistoryPhoto item={element} cardId={item.id} setModal={setCardModal} setModalId={setModalId}  /> }
                {(element.type === "exam" && element.filename.length > 0 && element.extension == "jpeg") && 
                  <HealthHistoryPhoto item={element} cardId={item.id} setModal={setCardModal} setModalId={setModalId}  /> }
                {(element.type === "vaccine" && element.filename.length > 0 && element.extension !== "jpeg") && 
                  <HealthHistoryPdf item={element} cardId={item.id} setModal={setCardModal} setModalId={setModalId}  /> }
                {(element.type === "prescription" && element.filename.length > 0 && element.extension !== "jpeg") && 
                  <HealthHistoryPdf item={element} cardId={item.id} setModal={setCardModal} setModalId={setModalId}  /> }
                {(element.type === "exam" && element.filename.length > 0 && element.extension !== "jpeg") && 
                  <HealthHistoryPdf item={element} cardId={item.id} setModal={setCardModal} setModalId={setModalId}  /> }
              </div>
            )})
          } 
        </HealthHistoryVerticalCardContainer>
      )}
    )
  }

  return (
    <div className={"container-simple-100vh"}>

      { createNewHistory && <HealthHistoryAddNewStory 
        setModalState={setCreateNewHistory} 
        subjectId={subjectId} setSubjectId={chooseSubject} /> 
      }

      { (cardModal && modalId.type==="description") && <HealthHistoryDescriptionModal modalId={modalId} setModalState={setCardModal} /> }  
      { (cardModal && modalId.type==="prescriptionText") && <HealthHistoryPrescriptionTextModal modalId={modalId} setModalState={setCardModal} /> }  
      { (cardModal && (modalId.type==="photo")) && 
        <HealthHistoryPhotoModal modalId={modalId} setModalState={setCardModal} /> 
      }
      { (cardModal && (modalId.type==="vaccine" || modalId.type==="exam" || modalId.type==="prescription")) && 
        <HealthHistoryPdfModal modalId={modalId} setModalState={setCardModal} /> 
      }
      { (cardModal && modalId.type==="addDescription") && <HealthHistoryNewDescriptionModal modalId={modalId} setModalState={setCardModal}  /> }
      { (cardModal && (modalId.type==="addExam" || modalId.type==="addPrescription" || modalId.type==="addPhoto" || modalId.type==="addVaccine")) && 
        <HealthHistoryAddFiles modalId={modalId} setModalState={setCardModal} />
      }

      { (!createNewHistory && !cardModal) &&

      <>
      <div className="health-history-header">
        <Link to={ "/profile"}>
          <img className="smaller-logo-nav" src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        </Link>

        <div className="health-history-header-title" >
          <p className={"intra-page-title vertical-padding-xs"}
            style={{ color: "#1A1F33" }}>
            { headerTitle } 
          </p>
          <button className={ "health-history-button-smaller"} onClick={()=>{setSubjectMenu(prev=>!prev)}}>
            <img className={"button-icon-smaller"} src={(subjectMenu) ? ChevronUp3 : ChevronDown3} alt="menu to change subject"/>
          </button>
        
          { subjectMenu && <HealthHistorySubjectMenu setSubjectMenu={chooseSubject} currentMenu={subjectId}/> }
        </div>


        { (tabletBreakpoint) ?
          <>
            <HealthHistoryButton onClick={() => setCreateNewHistory(prev=>!prev)}>Criar novo ficheiro</HealthHistoryButton>
          </> :
          <HealthHistoryToggleMenu setCreateNewHistory={() => setCreateNewHistory(prev=>!prev)} />
        }

      </div>

      <HealthHistoryHorizontalBoard>
        { userBoard }  
      </HealthHistoryHorizontalBoard>
    </>
    }
  </div>
  )
}
