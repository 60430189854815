import React from 'react'
import { Link } from "react-router-dom"
import SportBiker from '../images/sport-biker-blue.svg'
import Health from '../images/health-icon.svg'

export default function HeaderHabitsHealthSelector() {

  return (
      <div className="header-habits-health-pages">
        <Link to="/profile" className="typeface-no-margin">
          <div className="header-column-buttom">
            <img className="header-page-icon" src={SportBiker} alt="Habits page icon"/>
            <div className="small-header-button-selected">Criação de Hábitos</div>
          </div>
        </Link>
        <Link to="/healthhistory" className="typeface-no-margin">
          <div className="header-column-buttom">
            <img className="header-page-icon" src={Health} alt="Habits page icon"/>
            <div className="small-header-button">Dados de Saúde</div>
          </div>
        </Link>
      </div>
  )
}
