import React from 'react';
import { useLocation } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'
import DataAccessBlock from '../components/DataAccessBlock';
import Header from "../components/Header"
import HighlightedMessage from '../components/HighlightedMessage';
import LoginBlock from "../components/LoginBlock"
import RedefinePassword from "../components/RedefinePassword"
import Attention from "../images/sun.svg"
import AppBadges from '../components/AppBadges';

export default function LoginRequired() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const location = useLocation()

  const sendpassword = (location.state?.search === "sendpassword") ? true : false
  const password = (location.state?.search === "password") ? true : false
  const data = (location.state?.search === "data") ? true: false

  let sectionTitle = null
  if (data) {
    sectionTitle = <h1 className="florence-title top-margin-xl">Acessar dados compartilhados</h1>
  } else if (sendpassword) { 
    sectionTitle = <h1 className="florence-title top-margin-xl">Redefinir senha</h1>
  } else {
    sectionTitle = <h1 className="florence-title top-margin-xl">Faça seu login</h1>
  }

  return (
    <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet":"container-simple"}>
        <Header tabletBreakpoint={false} menuShow={true} loggedIn={false}/>
      </div>

      <div className="container-simple">

        <section>
          { sectionTitle }
        </section>
        
        {(password) ?
          <HighlightedMessage type="warning" icon={Attention} header="Aviso" 
          body="Sua nova senha foi enviada para seu e-mail. Caso você não receba nosso e-mail em alguns minutos, verifique sua caixa de spam."/> :
          null }

        {(data) ?
          <HighlightedMessage type="warning" icon={Attention} header="Aviso" 
          body="O código de acesso expira após 24 horas"/> :
          null }

      </div>

      <div className="top-margin-m bottom-margin-m">
        <div className={(tabletBreakpoint) ? 
          "use-app-message-background-tablet vertical-padding-m" : "use-app-message-background vertical-padding-m"}>
          <div className="container-simple">
            <h1 className="intra-page-title bottom-margin-s">Acesse pelo aplicativo</h1>
            <AppBadges />
          </div>
        </div>
      </div>

      <div className="top-margin-m bottom-margin-l">
        <div className={(tabletBreakpoint) ? 
          "colorful-background-tablet vertical-padding-m" : "colorful-background vertical-padding-m"}>
          <div className="container-simple">
            { (data) ? 
              <DataAccessBlock /> : 
              ( sendpassword ) ? <RedefinePassword /> : <LoginBlock password={password}/>
            }
          </div>
        </div>
      </div>
    </>
  )
}
