import React from 'react'
import Attention from "../images/sun.svg"
import Button from './Button'
import HighlightedMessage from './HighlightedMessage'

export default function HealthHistoryTextField({ 
  error, 
  handleSubmit, 
  folderFormData, 
  setFolderFormData,
  status,
  setEdit,
}) {
  
  const handleChange = (e) => {
    const { name, value } = e.target

    setFolderFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  return (
          <>
            {(error) ?
              <div className="bottom-margin-s">
                <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />  
              </div>
              : null }

            <form onSubmit={handleSubmit} className="login-form">
              <textarea 
                className="post-form bottom-margin-xs" 
                type="text" 
                name="text"
                onChange={handleChange} 
                value={folderFormData.text}
              />
              <div className="top-padding-xs">
                <Button type="primary right-margin-s">
                  {(status==="idle")? "Salvar" : "Salvando..."}
                </Button>
                <Button type="tertiary modal-teriary-button" 
                  buttonType="button" onClick={()=>setEdit(false)}>
                  Cancelar
                </Button>
              </div>
            </form>
          </>
  )
}
