import React from 'react'
import HealthHistoryButton from './HealthHistoryButton'
import Plus from '../images/plus.svg'
import Dots from '../images/dots-horizontal.svg'
import closeMenuLogo from "../images/x-solid-black.svg"
import Attention from "../images/sun.svg"
import { deleteDataFromApi, putRequestToApi } from '../api'
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import HighlightedMessage from './HighlightedMessage'
import Button from '../components/Button'

export default function HealthHistoryVerticalCardContainer({ 
  title, 
  children, 
  itemId,
  setCardModal,
  setModalId,
}) {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { setHealthHistory, healthHistory } = React.useContext(PortalContext);

  const [ newItemMenuState, setNewItemMenuState ] = React.useState(false)
  const [ cardMenuState, setCardMenuState ] = React.useState(false)
  const [ renameState, setRenameState ] = React.useState(false)
  const [ error, setError ] = React.useState(null)
  const [ status, setStatus ] = React.useState('idle')
  const [folderFormData, setfolderFormData] = React.useState({
    name: ""
  })
  
  const userRef = React.useRef()

  React.useEffect(() => {
    if (userRef.current) {
      userRef.current.focus()
    }
  }, []);

  const menuOptions = [
    {key: 0, element: <>Renomear ficheiro</>, onClick: ()=>{
      setRenameState(prev => !prev)
      setCardMenuState(prev => !prev)
    }
    },
    {key: 1, element: <>Deletar ficheiro</>, onClick: ()=>{deleteCard()} },
  ]
  

  const deleteCardFromBackend = async () => {

    console.log('request')
    console.log({id: itemId})

    await deleteDataFromApi({id: itemId}, "health/folder", auth, cookies)
  }

  const deleteCard = () => {
    deleteCardFromBackend()

    const updatedFolders = healthHistory[+cookies.get("userId")].filter(item => item.id !== itemId)
    setHealthHistory(prev => ({...prev, [+cookies.get("userId")]: updatedFolders}))
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setfolderFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }
  
  const handleSubmit = (e) => {
    e.preventDefault()

    if (folderFormData.name ) {

      setError(null)

      async function tryRenamingFolder(folderFormData) {
        try {
          setStatus("submitting")

          const requestBody = {
            id: itemId,
            name: folderFormData.name
          }

          const data = await putRequestToApi(requestBody, "/health/folder", auth, cookies)

          setAuth(prev => ({...prev, ...data.tokens}))

          const renamedFolder = healthHistory[+cookies.get("userId")].filter(item => item.id === itemId)
          renamedFolder[0].name = folderFormData.name
    
          const updatedFolders = healthHistory[+cookies.get("userId")].filter(item => item.id !== itemId)
          updatedFolders.push(renamedFolder[0])

          setHealthHistory(prev => ({...prev, [+cookies.get("userId")]: updatedFolders}))

        } catch(err) {
          setError(err.message)
        } finally {         
          setStatus("idle")
          setRenameState(false)
        }
      }
      
      tryRenamingFolder(folderFormData)

    } else {
      setError("É obrigatório dar um nome para o ficheiro")
    }
  }
  
  const addNewItem = (itemType) => {
    setCardModal(true)
    setModalId({ cardId: itemId, type: itemType })
  }

  const menu = menuOptions.map(({ key, element, onClick }) => (
    <button key={key} onClick={onClick} className="health-history-card-button right-padding-xs">
      {element}
    </button>
  ))

  const renameHistory = () => (
    <div>
        {(error) ?
          <div className="">
            <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />  
          </div>
          : null }

        <form onSubmit={handleSubmit} className="login-form">
          <input
            className="input-text bottom-margin-xs"
            ref = {userRef}
            name="name"
            onChange={handleChange}
            type="text"
            placeholder="Digite o novo nome ficheiro"
            value={folderFormData.name}
          />
          <div className="">
            <Button type="primary right-margin-s">
              {(status==="idle")? "Renomear" : "Renomeando..."}
            </Button>
            <Button type="tertiary modal-teriary-button" 
              buttonType="button" onClick={()=>setRenameState(false)}>
              Cancelar
            </Button>
          </div>
        </form>
    </div>
  )


  return (
    <div className={(!renameState) ? 
      "health-history-card-vertical-fixed": "health-history-card-vertical-fixed-rename"}>
      <div className="health-history-card-header">
        <p className="heading5 health-history-card-title">{title}</p>


        <button className={ "health-history-button-smaller"} onClick={()=>setCardMenuState(prev=>!prev)}>
          <img className={"button-icon-smaller"} src={Dots} alt="search button"/>
        </button>

        {(cardMenuState) &&
          <div className="health-history-menu-card bottom-padding-xs">
            <button className="svg-btn" onClick={()=>setCardMenuState(prev=>!prev)}>
              <img className="health-history-small-menu-nav health-history-inv-svg" 
                  src={closeMenuLogo} alt="Button to toggle menu" />
            </button>
            {menu}
          </div>
        }

      </div>

      {renameState && renameHistory()}
      
      <div className="health-history-card-vertical-scroll">
        { children }
      </div>

      {(newItemMenuState) &&
        <div className="health-history-menu-above " 
          style={(children.length < 3) ? { bottom: '-144px', left: '8px' } : null}>

          <HealthHistoryButton  onClick={()=>addNewItem("addPrescription")}>Prescrição</HealthHistoryButton>
          <HealthHistoryButton  onClick={()=>addNewItem("addExam")}>Exame</HealthHistoryButton>
          <HealthHistoryButton  onClick={()=>addNewItem("addVaccine")}>Vacina</HealthHistoryButton>
          <HealthHistoryButton  onClick={()=>addNewItem("addPhoto")}>Foto</HealthHistoryButton>
          <HealthHistoryButton onClick={()=>addNewItem("addDescription")}>Descrição</HealthHistoryButton>
        </div>
      }
      
      <HealthHistoryButton icon={Plus} onClick={()=>setNewItemMenuState(prev=>!prev)}>Adicionar novo item</HealthHistoryButton>

    </div>
  )
}
