import React from 'react';
import Button from './Button'

export default function ProductSection({title, text, img, breakpoint, laptopBreakpoint, buttonText, to, left, secondImg=null}) {

  return ( 
    <div className={(breakpoint) ? 
      (laptopBreakpoint) ? "product-horizontal-view-laptop vertical-margin-xxl" : "product-horizontal-view vertical-margin-xxl" : 
      "vertical-margin-xxl"}>
      <div className={(left) ? "product-content grid-last" : "product-content"}>
        <h1 className="intra-page-title home-centered-text">{title}</h1>
        <p className="body vertical-margin-l home-centered-text">{text}</p>
        {(to) ?
        <Button type="secondary bottom-margin-s" link={true} to={to}>{buttonText}</Button> : null }
      </div>

      <div>
        <img className={ breakpoint ? 
          "product-screenshot img-larger bottom-margin-xl" : "product-screenshot bottom-margin-xl"} 
          src={require(`../images/${img}`)} alt="Screenshot demonstrating functionality of the software"/>
        {(secondImg) && 
          <img className={ breakpoint ? 
            "product-screenshot img-larger bottom-margin-xl" : "product-screenshot bottom-margin-xl"} 
            src={require(`../images/${secondImg}`)} alt="Screenshot demonstrating functionality of the software"/>
        }
      </div>
    </div>
  )
}
