import React from 'react'
import HighlightedMessage from './HighlightedMessage'
import Attention from "../images/sun.svg"
import Button from '../components/Button'
import closeMenuLogo from "../images/x-solid-black.svg"
import { postRequestToApi } from '../api'
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import HealthHistorySubjectSelectionMenu from './HealthHistorySubjectSelectionMenu'

export default function HealthHistoryAddNewStory({ setModalState, subjectId, setSubjectId }) {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { setHealthHistory, healthHistory } = React.useContext(PortalContext);
  
  const [folderFormData, setfolderFormData] = React.useState({
    name: ""
  })
  const [error, setError] = React.useState(null)
  const [status, setStatus] = React.useState('idle')

  const userRef = React.useRef()

  React.useEffect(() => {
    if (userRef.current) {
      userRef.current.focus()
    }
  }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target

    setfolderFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }
  
  const handleSubmit = (e) => {
    e.preventDefault()

    if (folderFormData.name ) {

      setError(null)

      async function tryCreatingNewFolder(folderFormData) {
        try {
          setStatus("submitting")

          const requestBody = {
            id: +cookies.get("userId"),
            name: folderFormData.name,
            record_id: subjectId,
          }

          const data = await postRequestToApi(requestBody, "/health/folder", auth, cookies)

          setAuth(prev => ({...prev, ...data.tokens}))

          const updatedFolders = healthHistory[+cookies.get("userId")]
          updatedFolders.unshift({
            date_created: new Date(),
            id: data.id,
            items: [],
            name: folderFormData.name,
            subject_id: subjectId,
          })

          setHealthHistory(prev => ({...prev, [+cookies.get("userId")]: updatedFolders}))

        } catch(err) {
          setError(err.message)
        } finally {         
          setStatus("idle")
          setModalState(false)
        }
      }
      
      tryCreatingNewFolder(folderFormData)

    } else {
      setError("É obrigatório dar um nome para o novo ficheiro")
    }
  }
  
  if (subjectId === -1) {
    return (
      <HealthHistorySubjectSelectionMenu 
        chooseSubject={setSubjectId} 
        alternativeTitle="Qual o assunto do ficheiro?"
        showAllOption={false}
      />
    )
  }

  return (
    <div className="modal-background">
      <div className="modal-container">

        <div className="modal-close-button">
          <button className="svg-btn" onClick={()=>setModalState(false)}>
            <img className="health-history-small-menu-nav health-history-inv-svg" 
                src={closeMenuLogo} alt="Button to toggle menu" />
          </button>
        </div>

        <p className="intra-page-title bottom-margin-xs">Novo ficheiro</p>
         
        {(error) ?
          <div className="bottom-margin-s">
            <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />  
          </div>
          : null }

        <form onSubmit={handleSubmit} className="login-form">
          <p className="body">Nome</p>
          <input
            className="input-text bottom-margin-xs"
            ref = {userRef}
            name="name"
            onChange={handleChange}
            type="text"
            placeholder="Digite o nome do novo ficheiro"
            value={folderFormData.name}
          />
          <div className="top-padding-xs">
            <Button type="primary right-margin-s">
              {(status==="idle")? "Criar" : "Criando..."}
            </Button>
            <Button type="tertiary modal-teriary-button" 
              buttonType="button" onClick={()=>setModalState(false)}>
              Cancelar
            </Button>
          </div>
        </form>

      </div>
    </div>
  )
}
