import React from 'react';
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Return from "../components/Forum/Return"
import Button from '../components/Button';

export default function Deletion() {
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  // go to the top of the page
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet":"container-simple"}>
        <Header tabletBreakpoint={false} menuShow={true} loggedIn={false}/>
      </div>

      <div className={(tabletBreakpoint) ? "container-simple-tablet":"container-simple"}>

        <section className="top-margin-m bottom-padding-xl">

          <Return to="/" backTo="Voltar para inicial"/>

          <h1 className="florence-title top-margin-l">Como excluir sua conta</h1>

          <p className="body top-margin-s">
Se você decidiu parar de utilizar os serviços da Florence, o processo para excluir sua conta é simples:
          </p> 
          
          <div className="container-simple-narrow">
            <ul className="body top-margin-s">
              <li>Faça seu login em sua conta</li>
              <li>Navegue para a página "Minha conta"</li>
              <li>Siga os passos da seção "Excluir conta"</li>
            </ul>
          </div>
          
          <p className="body top-margin-s">
<strong>Importante: </strong>Note que essa é uma ação destrutiva, e que não poderá ser desfeita. Caso você exclua sua conta, todos os dados e informação associados a ela também serão excluídos.
          </p> 

        </section>
      </div>

      <Footer tabletBreakpoint={tabletBreakpoint} border="footer-no-right-border"/>
    </>
    
  )
}
