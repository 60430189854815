import { format } from 'date-fns'
import React from 'react'

export default function HealthHistoryPrescriptionText({ item, cardId, setModal, setModalId }) {

  const openModal = () => {
    setModal(prev => !prev)
    setModalId({ cardId, itemId: item.id, type: "prescriptionText" })
  }

  return (
      <div className="card-element" onClick={()=>openModal()}>
        <p className="body"><strong>
          {item.group_name}
        </strong></p>
        <p className="body health-history-description-thumbnail ">
          {item.content}
        </p>
      </div>
  )
}
